// gray-color-variables
$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f2f2f2 !default;
$gray-300: #e1e1e1 !default;
$gray-400: #bebebe !default;
$gray-500: #949494 !default;
$gray-600: #7F7F7F !default;
$gray-700: #666666 !default;
$gray-800: #555555 !default;
$gray-900: #404040 !default;
$gray-1000: #2b2b2b !default;
$gray-1100: #1c1c1c !default;
$black: #000 !default;

// gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
  '1000': $gray-1000,
  '1100': $gray-1100,
) !default;

// color-variables
$blue: #045bc1 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d0021b !default;
$orange: #fd7e14 !default;
$yellow: #f37f29 !default;
$green: #7ed321 !default;
$teal: #20c997 !default;
$cyan: #00d6ff !default;

// theme-color-variables
$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

// theme-color-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;

// brand-color-variables
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333333 !default;
$youtube: #ff0001 !default;

// brand-color-map
$brand-colors: (  
  'facebook': $facebook,
  'google-plus': $google-plus,
  'twitter': $twitter,
  'linkedin': $linkedin,
  'youtube': $youtube,
  'github': $github,
) !default;

$body-color: $gray-700 !default;
$body-bg: $white !default;

$body-secondary-color: $gray-600 !default;
$body-secondary-bg: $gray-200 !default;

$body-tertiary-color: $gray-500 !default;
$body-tertiary-bg: $gray-100 !default;

$body-highlight-color: $gray-900 !default;
$body-emphasis-color: $black !default;