/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  font-weight: $font-weight-bold;
  font-size: $font-size-base * 0.8;
  letter-spacing: 0.01em;
  border-bottom: $border-width solid rgba($white, 0.15);
  transition: $transition-base;
  
}
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
