:root,
[data-bs-theme="light"] {
  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.
  @each $color, $value in $brand-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $grays-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  @each $color, $value in $brand-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  // scss-docs-start root-body-variables
  --#{$prefix}body-highlight-color: #{$body-highlight-color};
  --#{$prefix}body-highlight-color-rgb: #{to-rgb($body-highlight-color)};
  // scss-docs-end root-body-variables
  
  // 
  // Fonts
  // 
  --#{$prefix}font-serif: #{inspect($font-family-serif)};

  // 
  // Fancynav
  // 
  --#{$prefix}fancynav-togglerbar-bg: #{$fancynav-togglerbar-bg};
  --#{$prefix}fancynav-collapse-bg: #{$fancynav-collapse-bg};
  --#{$prefix}fancynav-link-color: #{$fancynav-link-color};
  --#{$prefix}fancynav-link-color-rgb: #{to-rgb($fancynav-link-color)};

  .btn-dark{
    --#{$prefix}btn-hover-bg: #{shade-color($dark, 80%)};
    --#{$prefix}btn-hover-border-color: #{shade-color($dark, 80%)};
    --#{$prefix}btn-active-bg: #{shade-color($dark, 70%)};
  }
}