/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover{ object-fit: cover; }

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none{
  @include hover-focus{
    text-decoration: none;
  }
}
.resize-none{ resize: none; }

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}
.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

[data-dismiss="dropdown"],
[data-bs-offset-top],
[data-bs-toggle="collapse"],
[data-bs-toggle="tooltip"],
[data-bs-toggle="popover"]{
  *{
    pointer-events: none;
  }
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;
  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */

.transition-base {
  transition: $transition-base;
}
.transition-none{
  transition: none;
}

.fsp-75{
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

// used in chat
.min-w-0{ min-width: 0; }
 
/* -------------------------------------------------------------------------- */
/*                                    Divider                                   */
/* -------------------------------------------------------------------------- */

.divider-content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);
  background-color: $card-bg;
  font-family: $font-family-secondary;
  font-size: map-get($font-sizes, '-1');
  color: var(--#{$prefix}tertiary-color);
  white-space: nowrap;
}

/* -------------------------------------------------------------------------- */
/*                                    Zanimation                              */
/* -------------------------------------------------------------------------- */


*[data-zanim-trigger] {
  opacity: 0;
}

/*-----------------------------------------------
|   Times Icon
-----------------------------------------------*/
.times-icon {
  position: relative;
  outline: none;
  &:before,
  &:after {
    left: 0;
    position: absolute;
    content: "";
    width: 80%;
    height: 2px;
    background-color: $white;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }

  @include hover-focus {
    outline: none;
  }
}
.text-shadow-none {
  text-shadow: none !important;
}
.opacity-1 {
  opacity: 1 !important;
}
// Used to fix FontAwesome .fa-stack size
.fa-stack-size {
  height: 2em;
  width: 2em;
}

/*-----------------------------------------------
|   Pagination
-----------------------------------------------*/
.page-item{ margin: 0 map_get($spacers, 1); }
